import { Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import NavigationBar from "./NavigationBar";

// todo - parameterize NavigationBar
const LandingLayout = () => {
  const { user } = useAuth() || { user: null };

  return (
    <div>
      <NavigationBar />
      <Outlet />
    </div>
  );
}

export default LandingLayout;