import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  defer,
} from "react-router-dom";
import LandingPage from "./components/pages/landing";
import LoginPage from "./components/pages/login";
import HomePage from "./components/pages/home";
import AboutPage from "./components/pages/about";
import LandingLayout from "./components/LandingLayout";
import AuthLayout from "./components/AuthLayout";
import ProtectedLayout from "./components/ProtectedLayout";
import SettingsPage from "./components/pages/settings";

// This may end up being useful https://stackoverflow.com/questions/75652431/how-should-the-createbrowserrouter-and-routerprovider-be-use-with-application-co
// https://blog.logrocket.com/complete-guide-authentication-with-react-router-v6/

// ideally this would be an API call to server to get logged in user data
const getUserData = () =>
  new Promise((resolve) =>
    setTimeout(() => {
      const user = window.localStorage.getItem("user");
      resolve(user);
    }, 3000)
  );

// for error
// const getUserData = () =>
//   new Promise((resolve, reject) =>
//     setTimeout(() => {
//       reject("Error");
//     }, 3000)
//   );


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={() => defer({ userPromise: getUserData() })}
    >
      <Route element={<LandingLayout />}>
        <Route path="" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/about" element={<AboutPage />} />
      </Route>

      <Route path="/app" element={<ProtectedLayout />}>
        <Route path="home" element={<HomePage />} />
        <Route path="settings" element={<SettingsPage />} />
      </Route>
    </Route>
  )
);

export default function App() {
  return (
    <RouterProvider router={router} />
  );
}

